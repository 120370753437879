import Box from '@mui/material/Box'
import Drawer, { drawerClasses } from '@mui/material/Drawer'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import ListItem, { listItemClasses, ListItemProps } from '@mui/material/ListItem'
import ListItemButton, { listItemButtonClasses, ListItemButtonOwnProps } from '@mui/material/ListItemButton'
import { listItemTextClasses } from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import { alpha, styled, darken } from '@mui/material/styles'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { typographyClasses } from '@mui/material/Typography'

const isSidePanelLightEnabled = process.env.NEXT_PUBLIC_SIDE_PANEL_LIGHT === 'true'

const StyledSidebar = styled(Drawer)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  zIndex: 10,

  [`& .${drawerClasses.paper}`]: {
    boxSizing: 'border-box',
    borderRight: 0,
    backgroundColor: isSidePanelLightEnabled ? theme.palette.lightSurface.main : theme.palette.darkSurface.main,
  },
}))

export const navigationSidebarWidth = '93px'
export const negotiationSidebarWidth = 115 // px

export const StyledNavigationSideBar = styled(StyledSidebar, { shouldForwardProp: prop => prop !== 'setBoxShadow' })<
  IconButtonProps & { setBoxShadow?: boolean }
>(({ theme, setBoxShadow }) => ({
  width: navigationSidebarWidth,
  boxShadow: `0px 2px 5px ${alpha(theme.palette.darkSurface.main, 0.03)}`,

  ...(isSidePanelLightEnabled && {
    boxShadow: 'none',

    ...(setBoxShadow && {
      [`& .${drawerClasses.paper}`]: {
        boxShadow: `0px 4px 60px 0px ${alpha(theme.palette.common.black, 0.15)}`,
      },
    }),
  }),
}))

export const StyledNegotiationSideBar = styled(StyledSidebar)(({ theme }) => ({
  width: negotiationSidebarWidth,
  boxShadow: isSidePanelLightEnabled ? 'none' : `0px 2px 5px ${alpha(theme.palette.darkSurface.main, 0.03)}`,
  [`& .${drawerClasses.paper}`]: {
    boxShadow: isSidePanelLightEnabled ? `0px 4px 60px 0px ${alpha(theme.palette.common.black, 0.15)}` : 'none',
  },
}))

export const StyledMenuIconButton = styled(IconButton, { shouldForwardProp: prop => prop !== 'open' })<
  IconButtonProps & { open?: boolean }
>(({ theme, open }) => ({
  display: 'flex',
  color: open ? theme.palette.common.white : theme.palette.primaryLight.main,
  width: '46px',
  height: '38px',
  borderRadius: '8px',
  backgroundColor: open ? `${alpha(theme.palette.primaryLight.main, 0.08)}` : '',
}))

export const StyledListItemButton = styled(ListItemButton)<ListItemButtonOwnProps & { href?: string | null }>(
  ({ theme }) => ({
    height: '59px',
    width: '100%',
    borderRadius: '10px',
    flexDirection: 'column',
    justifyContent: 'center',
    color: isSidePanelLightEnabled ? theme.palette.primary.main : theme.palette.primaryLight.main,
    borderBottom: 'none',

    '&.Mui-selected': {
      backgroundColor: isSidePanelLightEnabled
        ? theme.palette.primary.main
        : `${alpha(theme.palette.primaryLight.main, 0.08)}`,
      color: theme.palette.common.white,
    },
    '&.MuiButtonBase-root': {
      margin: '0 auto',
      transition: `${theme.transitions.create(['background-color', 'color'], {
        duration: theme.transitions.duration.enteringScreen,
      })}`,
    },
    ...(isSidePanelLightEnabled && {
      '&.Mui-selected.MuiButtonBase-root:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    }),
    '&.MuiButtonBase-root:hover': {
      backgroundColor: `${alpha(theme.palette.primaryLight.main, isSidePanelLightEnabled ? 0.2 : 0.08)}`,
    },
    '&.MuiButtonBase-root:focus-visible': {
      backgroundColor: `${alpha(theme.palette.primaryLight.main, isSidePanelLightEnabled ? 0.25 : 0.12)}`,
    },
    '&.MuiButtonBase-root:active': {
      backgroundColor: `${alpha(theme.palette.primaryLight.main, isSidePanelLightEnabled ? 0.25 : 0.12)}`,
    },
    '.MuiListItemIcon-root': {
      color: isSidePanelLightEnabled ? theme.palette.primary.main : theme.palette.primaryLight.main,
      minWidth: 'fit-content',
      marginRight: 0,
    },
    '.MuiTypography-root': {
      overflow: 'hidden',
    },
    '&.Mui-selected .MuiListItemIcon-root': {
      color: theme.palette.common.white,
    },

    [`&.${listItemButtonClasses.dense}`]: {
      [`& .${listItemTextClasses.root}`]: {
        flexGrow: 0,
        margin: 0,
      },

      [`& .${typographyClasses.root}`]: {
        fontSize: '12px',
      },
    },
  })
)

export const StyledListItem = styled(ListItem)<ListItemProps>(({ theme }) => ({
  minWidth: 'fit-content',
  padding: theme.spacing(0.5, 0),

  [`&.${listItemClasses.gutters}`]: {
    padding: theme.spacing(0.5, 1.25),
  },

  [`&.${listItemClasses.divider}`]: {
    paddingBottom: theme.spacing(1.25),
    marginBottom: theme.spacing(0.75),
    borderBottomColor: alpha(theme.palette.primaryLight.main, 0.2),
  },
}))

export const StyledListItemSelectMode = styled(StyledListItem)({
  marginBottom: '24px',
})

export const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: isSidePanelLightEnabled ? theme.palette.lightSurface.main : theme.palette.darkSurface.main,
  height: '230px',
  width: '480px',
  borderRadius: '26px',
  boxShadow: `0 1px 8px 0 ${alpha(theme.palette.common.black, 0.5)}`,
}))

export const StyledPaperAdditionalOptions = styled(Paper)(({ theme }) => ({
  width: '344px',
  position: 'absolute',
  boxShadow: `0px 0px 8px 0px ${darken(theme.palette.common.black, 0.5)}`,
  backgroundColor: theme.palette.darkSurface.main,
  borderRadius: '16px',

  '& .MuiList-root': {
    padding: '12px',
  },
}))

// used as wrapper for custom SVG Icons to override the fill color. Used i.e. in "Library" & "Negotiation" Sidebars.
export const StyledSvgIcon = styled(SvgIcon, { shouldForwardProp: prop => prop !== 'isActive' })<
  SvgIconProps & { isActive: boolean }
>(({ theme, isActive }) => ({
  color: isActive ? theme.palette.common.white : 'inherit',
}))

export const StyledSvgBottomIcon = styled(SvgIcon, { shouldForwardProp: prop => prop !== 'isActive' })<
  SvgIconProps & { isActive: boolean }
>(({ theme, isActive }) => ({
  color: 'inherit',
  width: '48px',
  height: '48px',
  position: 'relative',
  bottom: '10px',
  circle: {
    stroke: isActive ? theme.palette.common.white : theme.palette.primary.main,
    ...(isSidePanelLightEnabled && { fill: isActive ? theme.palette.primary.main : theme.palette.lightSurface.main }),
  },
}))

export const StyledAnchor = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '0',
  left: '-60px',
  width: '60px',
  height: '70px',
  borderTopLeftRadius: '50%',
  borderBottomLeftRadius: '50%',
  backgroundColor: 'transparent',
  boxShadow: `0 1px 8px 0 ${alpha(theme.palette.common.black, 0.5)}`,
  transform: 'rotate(180deg) scaleX(-1)',
  cursor: 'pointer',

  '&:before': {
    content: `""`,
    width: '18px',
    height: '20px',
    position: 'absolute',
    bottom: '-20px',
    right: 0,
    borderRadius: '0 100% 0 0',
    boxShadow: `10px -10px 0 5px ${
      isSidePanelLightEnabled ? theme.palette.lightSurface.main : theme.palette.darkSurface.main
    }`,
  },
  '&:after': {
    content: `""`,
    width: '26px',
    height: '100%',
    position: 'absolute',
    top: '0',
    right: '-24px',
    backgroundColor: isSidePanelLightEnabled ? theme.palette.lightSurface.main : theme.palette.darkSurface.main,
    boxShadow: `0 -2px 2px -2px ${alpha(theme.palette.common.black, 0.5)}`,
  },
}))

export const StyledListItemButtonMode = styled(ListItemButton)(({ theme }) => ({
  maxWidth: '87px',
  height: '80px',
  borderRadius: '10px',
  flexDirection: 'column',
  justifyContent: 'center',
  color: isSidePanelLightEnabled ? theme.palette.primary.main : theme.palette.primaryLight.main,
  '&.MuiButtonBase-root:hover, &.Mui-selected': {
    backgroundColor: isSidePanelLightEnabled
      ? `${alpha(theme.palette.primary.main, 0.2)}`
      : `${alpha(theme.palette.primaryLight.main, 0.08)}`,
  },
}))
