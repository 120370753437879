import React, { ComponentType } from 'react'

import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Popover from '@mui/material/Popover'
import Skeleton from '@mui/material/Skeleton'
import { styled, alpha } from '@mui/material/styles'
import dynamic from 'next/dynamic'

import { confirmModalContainerWidth } from 'components/molecules/Modal/modals/ConfirmAndSaveModal/ConfirmAndSaveModal.styles'
import { partySelectorModalWidth } from 'components/molecules/Modal/modals/SelectCurrentParty'
import { ModalsPathMap, SkeletonOptions } from 'components/molecules/Modal/ModalWrapper.types'
import { workspaceRouterContainerWidth } from 'components/organisms/WorkspaceRouter/WorkspaceRouter.styles'

export const ModalWrapperContainer = styled(Popover)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: `${alpha(theme.palette.common.white, 0.5)}`,
  },
  '& .MuiPopover-paper': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
}))

export const ModalPaper = styled(Box, {
  shouldForwardProp: prop => prop !== 'ownerState',
})(() => ({
  width: 'fit-content',
}))

const defaultSkeletonLoaderProps: SkeletonOptions = { width: '530px', height: '467px', variant: 'rounded' }

function ModalLoading(props: SkeletonOptions) {
  return (
    <Fade in timeout={250}>
      <Skeleton {...props} />
    </Fade>
  )
}

export const ModalComponents: { [T in keyof ModalsPathMap]: ComponentType<ModalsPathMap[T]> } = {
  NegotiationFlowStart: dynamic(() => import(`components/molecules/Modal/modals/NegotiationFlowStart`), {
    loading: () => <ModalLoading {...defaultSkeletonLoaderProps} />,
  }),
  ConfirmAndSaveModal: dynamic(() => import(`components/molecules/Modal/modals/ConfirmAndSaveModal`), {
    loading: () => <ModalLoading width={confirmModalContainerWidth} height="400px" variant="rounded" />,
  }),
  SelectCurrentParty: dynamic(() => import(`components/molecules/Modal/modals/SelectCurrentParty`), {
    loading: () => <ModalLoading width={partySelectorModalWidth} height="150px" variant="rounded" />,
  }),
  SelectCounterParty: dynamic(() => import(`components/molecules/Modal/modals/SelectCounterParty`), {
    loading: () => <ModalLoading width={partySelectorModalWidth} height="150px" variant="rounded" />,
  }),
  TemplateSelector: dynamic(() => import(`components/molecules/Modal/modals/TemplateSelector`), {
    loading: () => <ModalLoading width="660px" height="467px" variant="rounded" />,
  }),
  UmbrellaModal: dynamic(() => import(`components/molecules/Modal/modals/UmbrellaModal`), {
    loading: () => (
      <ModalLoading
        width="calc(100vw - 30px)"
        height="calc(100vh - 90px)"
        variant="rounded"
        sx={{
          marginTop: '80px',
          marginBottom: '10px',
        }}
      />
    ),
  }),
  DocumentPreviewModal: dynamic(() => import(`components/molecules/Modal/modals/DocumentPreviewModal`), {
    loading: () => <ModalLoading width="639px" height="97vh" variant="rounded" />,
  }),
  AssignWorkspaceModal: dynamic(() => import(`components/molecules/Modal/modals/AssignWorkspaceModal`), {
    loading: () => <ModalLoading {...defaultSkeletonLoaderProps} />,
  }),
  WorkspaceRouterModal: dynamic(() => import(`components/molecules/Modal/modals/WorkspaceRouterModal`), {
    loading: () => <ModalLoading width={`${workspaceRouterContainerWidth}px`} height="300px" variant="rounded" />,
  }),
  BulkSendModal: dynamic(() => import(`components/molecules/Modal/modals/BulkSendModal`), {
    loading: () => <ModalLoading width="660px" height="600px" variant="rounded" />,
  }),
}
