import { ElectionColor } from 'utils/electionProgress/ElectionColor'
import { WeightedElectionColorSet } from 'utils/electionProgress/WeightedElectionColorSet'

/**
 * A pair of colours (to use in an election progress bar).
 *
 * The 'left' and 'right' color may be the same color.
 */
export interface WeightedElectionColorPair {
  /**
   * Indicates how much of the left color to display.
   * Value is a factor (0 to 1).
   */
  progress: number
  left: ElectionColor
  right: ElectionColor
}

export namespace WeightedElectionColorPair {
  // Colour order (top-to-bottom === left-to-right). Object used for type safety / to ensure we include all colours.
  const leftToRightColoursObj: Record<ElectionColor, null> = {
    success: null,
    warning: null,
    grey: null,
    transparent: null,
  }

  // Colour order (same data as above, but as a string array: "success", "warning", etc.)
  const leftToRightColours = Object.keys(leftToRightColoursObj) as Array<ElectionColor>

  // Fallback color to use as the 'background' if only one
  const defaultColourIndex = leftToRightColours.length - 1

  // Predicate for non-zero numbers.
  const isNonZero = (value: number): boolean => value > 0

  // Same as Array.findIndex, except includes a 'fromIndex' param to start the search from, and returns 'null' instead of -1,
  const findIndex = <T>(array: T[], predicate: (value: T) => boolean, fromIndex?: number): number | null => {
    const index = array.slice(fromIndex).findIndex(predicate)
    return index === -1 ? null : index + (fromIndex ?? 0)
  }

  /**
   * Given a set of weighted election colours, returns a pair of weighted election colours (to use in a
   * binary-palette progress bar).
   */
  export function calculate(colors: WeightedElectionColorSet): WeightedElectionColorPair {
    const colourAmounts = leftToRightColours.map(color => colors[color])
    const leftIndex = findIndex(colourAmounts, isNonZero) ?? defaultColourIndex
    const rightIndex = findIndex(colourAmounts, isNonZero, leftIndex + 1) ?? defaultColourIndex
    const leftColourAmount = colourAmounts[leftIndex]
    const rightColourAmount = colourAmounts[rightIndex]
    const left = leftToRightColours[leftIndex]
    const right = leftToRightColours[rightIndex]
    const is0 = left === 'transparent' && right === 'transparent'
    const is100 = rightColourAmount === 0 || left === right
    return {
      left,
      right,
      progress: is0 ? 0 : is100 ? 1 : leftColourAmount / (leftColourAmount + rightColourAmount),
    }
  }

  export function getSummaryColor(colors: WeightedElectionColorPair): ElectionColor {
    return colors.progress === 1 ? colors.left : colors.right
  }
}
