import { Dict, OverridedMixpanel } from 'mixpanel-browser'

import { ModalsPathMap, SetModalParams } from 'components/molecules/Modal/ModalWrapper.types'
import { CommentChannelType } from 'components/organisms/NegotiationSidePanels'
import { AccountContext } from 'types/UserContext'
import pseudonymise from 'utils/pseudonymise'

import { consts } from './Mixpanel.consts'
import { MixpanelAPI } from './Mixpanel.types'

export default class MixpanelInstance implements MixpanelAPI {
  private _mixpanel: OverridedMixpanel

  private _env: string

  constructor(mixpanel: OverridedMixpanel, env: string) {
    this._mixpanel = mixpanel
    this._env = env
  }

  private getAccountInfo(account: AccountContext | undefined) {
    const { accountName, isOneLink } = account || {}
    return { accountName: pseudonymise(accountName || ''), accountIsOneLink: isOneLink }
  }

  identify(userId: string) {
    this._mixpanel.identify(userId)
  }

  track(name: string, props?: Dict): void {
    const trackedProps = {
      ...props,
      environment: this._env,
      fromBrowser: true,
    }
    this._mixpanel.track(name, trackedProps)
  }

  trackView(path: string, search?: string): void {
    this._mixpanel.track_pageview({ path, search, environment: this._env })
  }

  set(props: Dict): void {
    this._mixpanel.people.set(props)
  }

  reset(): void {
    this._mixpanel.reset()
  }

  alias(id: string): void {
    this._mixpanel.alias(id)
  }

  trackEvent = {
    LOGIN: (account: AccountContext | undefined) => {
      const { accountName, accountIsOneLink } = this.getAccountInfo(account)
      this.track(consts.LOGIN, { accountName, accountIsOneLink })
    },
    QUICK_START_NEGOTIATION: () => {
      this.track(consts.QUICK_START_NEGOTIATION)
    },
    NEGOTIATION_CREATED: (
      documentId: string,
      docName: string,
      negotiationId: string,
      eventOrigin: Required<SetModalParams<keyof ModalsPathMap>>['eventOrigin'] | 'custodian-template',
      templateId?: string
    ) => {
      this.track(consts.NEGOTIATION_CREATED?.[eventOrigin], {
        documentId,
        document: docName,
        templateId,
        negotiationId,
      })
    },
    ADD_COMMENT: (channel: CommentChannelType, negotiationId: string) => {
      this.track(consts.ADD_COMMENT[channel], { negotiationId })
    },
    EXTERNAL_COMMENT_COVER_NOTE: (negotiationId: string) => {
      this.track(consts.EXTERNAL_COMMENT_COVER_NOTE, { negotiationId })
    },
    DOCLEVEL_APPROVAL_APPROVED: (negotiationId: string) => {
      this.track(consts.DOCLEVEL_APPROVAL_APPROVED, { negotiationId })
    },
    DOCLEVEL_APPROVAL_REJECTED: (negotiationId: string) => {
      this.track(consts.DOCLEVEL_APPROVAL_REJECTED, { negotiationId })
    },
    ELECTION_APPROVAL_APPROVED: (negotiationId: string, electionId: string) => {
      this.track(consts.ELECTION_APPROVAL_APPROVED, { negotiationId, electionId })
    },
    ELECTION_APPROVAL_REJECTED: (negotiationId: string, electionId: string) => {
      this.track(consts.ELECTION_APPROVAL_REJECTED, { negotiationId, electionId })
    },
    NEGOTIATION_EXECUTED: (negotiationId: string) => {
      this.track(consts.NEGOTIATION_EXECUTED, { negotiationId })
    },
  }
}
