import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

export const workspaceRouterContainerWidth = 770 // px

export const WorkspaceRouterContainer = styled(Stack)(({ theme }) => ({
  borderRadius: '20px',
  padding: theme.spacing(2, 1),
  backgroundColor: theme.palette.lightSurface.main,
  width: `${workspaceRouterContainerWidth}px`,
  gap: theme.spacing(2.5),
}))

export default {}
