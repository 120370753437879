import { ApprovalState, NegotiationState } from 'types/Negotiation'
import { NegotiationWorkflowType } from 'types/Negotiations'

const DraftNegotiationStates = [
  NegotiationState.Draft,
  NegotiationState.ReceiverDraft,
  NegotiationState.Inviting,
] as const satisfies NegotiationState[]
export type DraftNegotiationState = (typeof DraftNegotiationStates)[number]
export function isDraftNegotiation(negState: NegotiationState): negState is DraftNegotiationState {
  return (DraftNegotiationStates as NegotiationState[]).includes(negState)
}

const OfflineUnilateralExecutionWorkflowTypes = [
  'offline',
  'w1ExtCombinedStageOffline',
] as const satisfies NegotiationWorkflowType[]
export type OfflineUnilateralExecutionWorkflowType = (typeof OfflineUnilateralExecutionWorkflowTypes)[number]
export function isOfflineWorkflowTypeWithUnilateralExecution(
  workflowType: NegotiationWorkflowType
): workflowType is OfflineUnilateralExecutionWorkflowType {
  return (OfflineUnilateralExecutionWorkflowTypes as NegotiationWorkflowType[]).includes(workflowType)
}

const OfflineWorkflowTypes = [
  'offline',
  'w1ExtOffline',
  'w1ExtStage2Offline',
  'w1ExtCombinedStageOffline',
] as const satisfies NegotiationWorkflowType[]
export type OfflineWorkflowType = (typeof OfflineWorkflowTypes)[number]
export function isOfflineWorkflowType(workflowType: NegotiationWorkflowType): workflowType is OfflineWorkflowType {
  return (OfflineWorkflowTypes as NegotiationWorkflowType[]).includes(workflowType)
}

const BilateralOfflineWorkflowTypes = ['offline'] as const satisfies NegotiationWorkflowType[]
export type BilateralOfflineWorkflowType = (typeof BilateralOfflineWorkflowTypes)[number]
export function isBilateralOfflineWorkflowType(
  workflowType: NegotiationWorkflowType
): workflowType is BilateralOfflineWorkflowType {
  return (BilateralOfflineWorkflowTypes as NegotiationWorkflowType[]).includes(workflowType)
}

const TripartiteStage1WorkflowTypes = ['w1Ext', 'w1ExtOffline'] as const satisfies NegotiationWorkflowType[]
export type TripartiteStage1WorkflowType = (typeof TripartiteStage1WorkflowTypes)[number]
export function isTripartiteStage1WorkflowType(
  workflowType: NegotiationWorkflowType
): workflowType is TripartiteStage1WorkflowType {
  return (TripartiteStage1WorkflowTypes as NegotiationWorkflowType[]).includes(workflowType)
}

const TripartiteStage2WorkflowTypes = ['w1ExtStage2', 'w1ExtStage2Offline'] as const satisfies NegotiationWorkflowType[]
export type TripartiteStage2WorkflowType = (typeof TripartiteStage2WorkflowTypes)[number]
export function isTripartiteStage2WorkflowType(
  workflowType: NegotiationWorkflowType
): workflowType is TripartiteStage2WorkflowType {
  return (TripartiteStage2WorkflowTypes as NegotiationWorkflowType[]).includes(workflowType)
}

const ElectionApprovalRequiredStates = ['Rejected', 'ApprovalRequired'] as const satisfies ApprovalState[]
export type ElectionApprovalRequiredState = (typeof ElectionApprovalRequiredStates)[number]
export function isElectionApprovalRequired(
  electionApprovalState: ApprovalState
): electionApprovalState is ElectionApprovalRequiredState {
  return (ElectionApprovalRequiredStates as ApprovalState[]).includes(electionApprovalState)
}

const DocumentApprovalRequiredStates = ['Rejected', 'Pending', 'ApprovalRequired'] as const satisfies ApprovalState[]
export type DocumentApprovalRequiredState = (typeof DocumentApprovalRequiredStates)[number]
export function isDocumentApprovalRequired(
  documentApprovalState: ApprovalState
): documentApprovalState is DocumentApprovalRequiredState {
  return (DocumentApprovalRequiredStates as ApprovalState[]).includes(documentApprovalState)
}
