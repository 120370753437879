import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getJWTCookie } from 'redesignStore/api/utils/common'
import enhanceBaseQuery from 'redesignStore/api/utils/enhanceBaseQuery'
import serializeRequestParams from 'redesignStore/api/utils/serializeRequestParams'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_REDESIGN_API_BASE_URL,
  credentials: 'include', // Include cookies with all requests so that httpOnly cookies (BE SessionId) are transmitted for cross-authentication
  prepareHeaders(headers) {
    const token = getJWTCookie()

    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
  paramsSerializer: serializeRequestParams,
})

// This exported list is used to invalidate all tags when Context is refreshed (which might change the WorkspaceId)
export const AllTagTypesApartFromContext = [
  'AdminAccounts',
  'AdminDocuments',
  'AdminDocumentPacks',
  'AdminDocumentPublishers',
  'AdminFeatures',
  'AdminInvitations',
  'AdminPendingAccounts',
  'AdminUsers',
  'AdvisorRelationship',
  'AdvisorRelationshipById',
  'AdvisorRelationshipSummary',
  'ApprovalComments',
  'BulkCount',
  'BulkNegotiations',
  'BulkSet',
  'BulkSets',
  'Comments',
  'DocumentSchema',
  'Docusign',
  'Entity',
  'ExecutedVersionStatus',
  'Expiration Window',
  'Invitation',
  'NakhodaUsers',
  'Negotiation',
  'Negotiations',
  'NestedAnswers',
  'Notifications',
  'PublicAccountName',
  'Search',
  'SignedSignatureStatus',
  'Statistics',
  'Template',
  'Users',
  'Workspaces',
  'CommentsSummary',
]

const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: enhanceBaseQuery(baseQuery),
  endpoints: () => ({}),
  keepUnusedDataFor: 0, // Clear any cached query data as soon as components using the query are unmounted
  tagTypes: ['Context', ...AllTagTypesApartFromContext],
})

export default baseApi
