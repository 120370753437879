'use client'

import { createContext, useContext } from 'react'

import NoopMixpanelInstance from 'components/integrations/Mixpanel/NoopMixpanelInstance'

import { MixpanelAPI } from './Mixpanel.types'

export const MixpanelContext = createContext<MixpanelAPI>(new NoopMixpanelInstance())

export function useMixpanel(): MixpanelAPI {
  return useContext(MixpanelContext)
}
