'use client'

import React, { ComponentType, ReactNode, useCallback, useMemo, useRef, useState } from 'react'

import Fade from '@mui/material/Fade'
import { PopoverActions } from '@mui/material/Popover'

import { ModalComponents, ModalPaper, ModalWrapperContainer } from 'components/molecules/Modal/ModalWrapper.components'
import { predefinedPositions } from 'components/molecules/Modal/ModalWrapper.consts'
import { ModalContext } from 'components/molecules/Modal/ModalWrapper.context'
import {
  ModalContextState,
  ModalObserver,
  ModalsPathMap,
  SetModalParams,
} from 'components/molecules/Modal/ModalWrapper.types'

export default function ModalWrapper({ children }: { children: ReactNode }) {
  const [modalParams, setModalParams] = useState<SetModalParams<keyof ModalsPathMap> | null>(null)

  // use a ref copy of the state to avoid re-initialising context
  const modalParamsRef = useRef(modalParams)

  const popoverActions = useRef<PopoverActions>(null)

  const CustomComponent =
    modalParams &&
    (ModalComponents[modalParams.modalComponent.modalComponentPath] as ComponentType<
      typeof modalParams.modalComponent.modalComponentProps
    >)

  const observers = useRef<ModalObserver[]>([])

  const open: ModalContextState['open'] = useCallback(options => {
    setModalParams(options)
    modalParamsRef.current = options
    observers.current.forEach(observer => observer(true))
  }, [])

  const close: ModalContextState['close'] = useCallback(() => {
    setModalParams(null)
    modalParamsRef.current = null
    observers.current.forEach(observer => observer(false))
  }, [])

  const subscribe: ModalContextState['subscribe'] = useCallback(observer => {
    observers.current.push(observer)

    return {
      unsubscribe() {
        observers.current = observers.current.filter(o => o !== observer)
      },
    }
  }, [])

  const childContext: ModalContextState = useMemo(
    () => ({
      open,
      close,
      updatePosition() {
        popoverActions.current?.updatePosition()
      },
      getModalParams() {
        return modalParamsRef.current
      },
      subscribe,
    }),
    [close, open, subscribe]
  )

  return (
    <ModalContext.Provider value={childContext}>
      <ModalWrapperContainer
        TransitionComponent={Fade}
        slots={{ paper: ModalPaper }}
        slotProps={{
          root: {
            slotProps: {
              backdrop: {
                invisible: false,
              },
            },
            sx: {
              ...(!modalParams?.eventTargetId && {
                // Note: defaulting any modal to the center if no "id=" attr is passed to the clicked target e.g <Button id="something" ... /> (passed by eventTargetId)
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }),
            },
          },
          paper: {
            sx: {
              // only use position: absolute, if we aren't positioning against another element
              position: modalParams?.eventTargetId ? 'absolute' : 'static',
            },
            role: 'dialog',
          },
        }}
        open={!!modalParams}
        onClose={() => {
          if (!modalParams?.ignoreClickOutside) {
            close()
          }
        }}
        {...(modalParams?.eventTargetId && {
          anchorEl: document.getElementById(modalParams.eventTargetId),
        })}
        action={popoverActions}
        anchorReference={modalParams?.modalComponent.modalComponentPosition?.anchorReference ?? 'anchorEl'}
        anchorPosition={modalParams?.modalComponent.modalComponentPosition?.anchorPosition ?? undefined}
        transformOrigin={
          modalParams?.modalComponent.modalComponentPosition?.transformOrigin ??
          predefinedPositions.right.transformOrigin
        }
        anchorOrigin={
          modalParams?.modalComponent.modalComponentPosition?.anchorOrigin ?? predefinedPositions.right.anchorOrigin
        }
        marginThreshold={modalParams?.modalComponent.modalComponentPosition?.marginThreshold ?? 16} // MUI default
      >
        {CustomComponent && modalParams && <CustomComponent {...modalParams.modalComponent.modalComponentProps} />}
      </ModalWrapperContainer>

      {children}
    </ModalContext.Provider>
  )
}
