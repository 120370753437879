import hashCode from 'utils/hashCode'

const adjectives = [
  'angelic',
  'appealing',
  'appreciable',
  'appreciated',
  'appreciative',
  'appropriate',
  'ardent',
  'articulate',
  'artistic',
  'assuring',
  'astonished',
  'astonishing',
  'astounded',
  'astounding',
  'athletic',
  'attentive',
  'attractive',
  'audible',
  'auspicious',
  'authentic',
  'autonomous',
  'available',
  'avid',
  'awed',
  'awesome',
  'awestruck',
  'beauteous',
  'beautiful',
  'believable',
  'beloved',
  'beneficent',
  'beneficial',
  'benevolent',
  'blameless',
  'blissful',
  'blithe',
  'bonny',
  'booming',
  'boundless',
  'bountiful',
  'brainiest',
  'brainy',
  'brave',
  'breathtaking',
  'bright',
  'brighter',
  'brightest',
  'brilliant',
  'brisk',
  'brotherly',
  'bullish',
  'buoyant',
  'calm',
  'calming',
  'capable',
  'captivating',
  'carefree',
  'catchy',
  'celebrated',
  'celebratory',
  'champion',
  'charismatic',
  'charitable',
  'charming',
  'chaste',
  'cheaper',
  'cheapest',
  'cheerful',
  'cheery',
  'cherished',
  'chivalrous',
  'classic',
  'classy',
  'clean',
  'cleaner',
  'cleanest',
  'cleanly',
  'clear',
  'cleared',
  'clearer',
  'clever',
  'coherent',
  'cohesive',
  'colorful',
  'comely',
  'comfortable',
  'comforting',
  'comfy',
  'commendable',
  'commodious',
  'compact',
  'compatible',
  'competitive',
  'compliant',
  'conciliatory',
  'concise',
  'confident',
  'congenial',
  'considerate',
  'consistent',
  'constructive',
  'consummate',
  'contrasty',
  'convenient',
  'convincing',
  'cool',
  'coolest',
  'cooperative',
  'correct',
  'courageous',
  'courteous',
  'courtly',
  'cozy',
  'creative',
  'credible',
  'crisp',
  'crisper',
  'cushy',
  'cute',
  'daring',
  'darling',
  'dashing',
  'dauntless',
  'dazzled',
  'dazzling',
  'decent',
  'decisive',
  'dedicated',
  'defeated',
  'deft',
  'delectable',
  'delicate',
  'delicious',
  'delighted',
  'delightful',
  'dependable',
  'deserving',
  'desirable',
  'desirous',
  'detachable',
  'devout',
  'dexterous',
  'dextrous',
  'dignified',
  'diligent',
  'diplomatic',
  'distinctive',
  'diversified',
  'divine',
  'dominated',
  'dumbfounded',
  'durable',
  'dynamic',
  'eager',
  'earnest',
  'eased',
  'easy',
  'easygoing',
  'ebullient',
  'economical',
  'ecstatic',
  'educated',
  'effective',
  'efficacious',
  'efficient',
  'effortless',
  'effusive',
  'elated',
  'elegant',
  'elite',
  'eloquent',
  'eminent',
  'enchanted',
  'enchanting',
  'encouraging',
  'endearing',
  'endorsed',
  'energetic',
  'engaging',
  'engrossing',
  'enhanced',
  'enjoyable',
  'enraptured',
  'enterprising',
  'entertaining',
  'enthralled',
  'enthusiastic',
  'enticing',
  'entranced',
  'entrancing',
  'enviable',
  'envious',
  'equitable',
  'erudite',
  'ethical',
  'euphoric',
  'evaluative',
  'eventful',
  'everlasting',
  'evocative',
  'exalted',
  'exalting',
  'exceeding',
  'excellent',
  'exceptional',
  'excited',
  'exciting',
  'exemplary',
  'exhilarating',
  'expansive',
  'exquisite',
  'exuberant',
  'exultant',
  'fabulous',
  'fair',
  'faithful',
  'famed',
  'famous',
  'fancier',
  'fancy',
  'fantastic',
  'fascinating',
  'fashionable',
  'fast',
  'faster',
  'fastest',
  'faultless',
  'favorable',
  'favored',
  'favorite',
  'fearless',
  'feasible',
  'feisty',
  'felicitous',
  'fertile',
  'fervent',
  'fervid',
  'festive',
  'fiery',
  'fine',
  'finer',
  'finest',
  'firmer',
  'flashy',
  'flatter',
  'flattering',
  'flawless',
  'flexible',
  'flourishing',
  'fluent',
  'fond',
  'foolproof',
  'foremost',
  'formidable',
  'fortuitous',
  'fortunate',
  'fragrant',
  'free',
  'freed',
  'fresh',
  'fresher',
  'freshest',
  'friendly',
  'frugal',
  'fruitful',
  'fun',
  'funny',
  'futuristic',
  'gainful',
  'gallant',
  'galore',
  'generous',
  'genial',
  'gentle',
  'gentlest',
  'genuine',
  'gifted',
  'glad',
  'glamorous',
  'gleeful',
  'glimmering',
  'glistening',
  'glorious',
  'glowing',
  'godlike',
  'gold',
  'golden',
  'good',
  'goodly',
  'gorgeous',
  'graceful',
  'gracious',
  'grand',
  'grateful',
  'gratified',
  'gratifying',
  'great',
  'greatest',
  'guiltless',
  'gutsy',
  'halcyon',
  'hale',
  'hallowed',
  'handier',
  'handsome',
  'handy',
  'happier',
  'happy',
  'hardier',
  'hardy',
  'harmless',
  'harmonious',
  'healthful',
  'healthy',
  'heartening',
  'heartfelt',
  'heartwarming',
  'heavenly',
  'helpful',
  'heroic',
  'hilarious',
  'holy',
  'honest',
  'honorable',
  'honored',
  'hopeful',
  'hospitable',
  'hot',
  'hottest',
  'humane',
  'humble',
  'humorous',
  'humourous',
  'ideal',
  'idolized',
  'idyllic',
  'illuminating',
  'illustrious',
  'imaginative',
  'immaculate',
  'immense',
  'impartial',
  'impassioned',
  'impeccable',
  'important',
  'impressed',
  'impressive',
  'improved',
  'improving',
  'incredible',
  'indebted',
  'indulgent',
  'industrious',
  'inestimable',
  'inexpensive',
  'infallible',
  'influential',
  'ingenious',
  'ingenuous',
  'innocuous',
  'innovative',
  'insightful',
  'inspiring',
  'instructive',
  'instrumental',
  'intelligent',
  'intelligible',
  'interesting',
  'intimate',
  'intricate',
  'intriguing',
  'intuitive',
  'invaluable',
  'inventive',
  'invigorating',
  'invincible',
  'inviolable',
  'inviolate',
  'invulnerable',
  'irresistible',
  'jolly',
  'jovial',
  'joyful',
  'joyous',
  'jubilant',
  'judicious',
  'keen',
  'kindly',
  'laudable',
  'lavish',
  'lawful',
  'leading',
  'lean',
  'legendary',
  'lighter',
  'likable',
  'like',
  'liked',
  'lionhearted',
  'lively',
  'logical',
  'lovable',
  'loved',
  'lovely',
  'loving',
  'loyal',
  'lucid',
  'luckier',
  'luckiest',
  'lucky',
  'lucrative',
  'luminous',
  'lush',
  'lustrous',
  'luxuriant',
  'luxurious',
  'luxury',
  'lyrical',
  'magic',
  'magical',
  'magnanimous',
  'magnificent',
  'majestic',
  'manageable',
  'marvellous',
  'marvelous',
  'masterful',
  'matchless',
  'mature',
  'meaningful',
  'memorable',
  'merciful',
  'meritorious',
  'merry',
  'mesmerized',
  'mesmerizing',
  'meticulous',
  'mighty',
  'miraculous',
  'modern',
  'modest',
  'momentous',
  'monumental',
  'motivated',
  'navigable',
  'neat',
  'neatest',
  'nice',
  'nifty',
  'nimble',
  'noble',
  'noiseless',
  'noteworthy',
  'nourishing',
  'observant',
  'obtainable',
  'optimal',
  'optimistic',
  'opulent',
  'orderly',
  'organized',
  'outstanding',
  'overjoyed',
  'painless',
  'palatial',
  'pampered',
  'panoramic',
  'paramount',
  'passionate',
  'patient',
  'patri',
  'peaceable',
  'peaceful',
  'peerless',
  'peppy',
  'perfect',
  'permissible',
  'personalized',
  'phenomenal',
  'picturesque',
  'playful',
  'pleasant',
  'pleased',
  'pleasing',
  'pleasurable',
  'plentiful',
  'plush',
  'poetic',
  'poignant',
  'poised',
  'polished',
  'polite',
  'popular',
  'portable',
  'posh',
  'positive',
  'powerful',
  'praiseworthy',
  'praising',
  'precious',
  'precise',
  'preeminent',
  'preferable',
  'premier',
  'prestigious',
  'pretty',
  'priceless',
  'principled',
  'privileged',
  'prize',
  'proactive',
  'prodigious',
  'productive',
  'proficient',
  'profound',
  'progressive',
  'prolific',
  'prominent',
  'promised',
  'promising',
  'prompt',
  'proper',
  'propitious',
  'prosperous',
  'protective',
  'proud',
  'proven',
  'prudent',
  'punctual',
  'pure',
  'purposeful',
  'quaint',
  'qualified',
  'quicker',
  'quiet',
  'quieter',
  'radiant',
  'rapid',
  'rapt',
  'rapturous',
  'rational',
  'reachable',
  'readable',
  'ready',
  'realistic',
  'realizable',
  'reasonable',
  'reasoned',
  'receptive',
  'recommended',
  'redeeming',
  'refined',
  'reformed',
  'refreshed',
  'refreshing',
  'regal',
  'rejoicing',
  'relaxed',
  'reliable',
  'remarkable',
  'renewed',
  'renowned',
  'replaceable',
  'reputable',
  'resilient',
  'resolute',
  'resounding',
  'resourceful',
  'respectable',
  'respectful',
  'resplendent',
  'responsive',
  'restful',
  'restored',
  'retractable',
  'reverent',
  'rewarding',
  'rich',
  'righteous',
  'rightful',
  'robust',
  'romantic',
  'roomier',
  'rosy',
  'safe',
  'saintly',
  'salutary',
  'sane',
  'satisfactory',
  'satisfied',
  'satisfying',
  'scenic',
  'seamless',
  'seasoned',
  'secure',
  'selective',
  'sensational',
  'sensible',
  'sensitive',
  'serene',
  'sexy',
  'sharp',
  'sharper',
  'sharpest',
  'shiny',
  'significant',
  'silent',
  'simpler',
  'simplest',
  'simplified',
  'sincere',
  'skilled',
  'skillful',
  'sleek',
  'slick',
  'smart',
  'smarter',
  'smartest',
  'smelly',
  'smiling',
  'smitten',
  'smooth',
  'smoother',
  'smoothest',
  'snappy',
  'snazzy',
  'sociable',
  'soft',
  'softer',
  'solid',
  'soulful',
  'spacious',
  'sparkling',
  'spectacular',
  'speedy',
  'spellbinding',
  'spellbound',
  'spirited',
  'spiritual',
  'splendid',
  'spontaneous',
  'sporty',
  'spotless',
  'sprightly',
  'stable',
  'stainless',
  'stately',
  'statuesque',
  'staunch',
  'steadfast',
  'steadiest',
  'steady',
  'stellar',
  'stimulating',
  'stimulative',
  'streamlined',
  'striking',
  'strong',
  'stronger',
  'strongest',
  'stunned',
  'stunning',
  'stupendous',
  'sturdier',
  'sturdy',
  'stylish',
  'stylized',
  'suave',
  'sublime',
  'subsidized',
  'substantive',
  'succeeding',
  'successful',
  'sufficient',
  'suitable',
  'sumptuous',
  'super',
  'superb',
  'superior',
  'supple',
  'supportive',
  'supreme',
  'surreal',
  'sustainable',
  'swankier',
  'swankiest',
  'swanky',
  'sweeping',
  'sweet',
  'sweetheart',
  'swift',
  'talented',
  'tantalizing',
  'tempting',
  'tenacious',
  'tender',
  'terrific',
  'thankful',
  'thinner',
  'thoughtful',
  'thrifty',
  'thrilled',
  'thrilling',
  'thriving',
  'tidy',
  'timely',
  'titillating',
  'tolerable',
  'top',
  'topnotch',
  'tough',
  'tougher',
  'toughest',
  'tranquil',
  'transparent',
  'trendy',
  'triumphal',
  'triumphant',
  'trusted',
  'trusting',
  'trustworthy',
  'trusty',
  'truthful',
  'twinkly',
  'unabashed',
  'unaffected',
  'unassailable',
  'unbeatable',
  'unbiased',
  'unbound',
  'undamaged',
  'undaunted',
  'undisputable',
  'undisputed',
  'unencumbered',
  'unequivocal',
  'unfettered',
  'unlimited',
  'unmatched',
  'unparalleled',
  'unreal',
  'unrestricted',
  'unrivaled',
  'unselfish',
  'unwavering',
  'upbeat',
  'upscale',
  'usable',
  'useful',
  'valiant',
  'valuable',
  'verifiable',
  'veritable',
  'versatile',
  'vibrant',
  'victorious',
  'viewable',
  'vigilant',
  'virtuous',
  'visionary',
  'vivacious',
  'vivid',
  'warm',
  'warmer',
  'warmhearted',
  'wealthy',
  'welcome',
  'wholesome',
  'wieldy',
  'winning',
  'wise',
  'witty',
  'wonderful',
  'wondrous',
  'workable',
  'worth',
  'worthwhile',
  'worthy',
  'young',
  'youthful',
  'zippy',
]

const nouns = [
  'aardvark',
  'albatross',
  'alligator',
  'angelfish',
  'angora',
  'ant',
  'anteater',
  'antelope',
  'armadillo',
  'avocet',
  'baboon',
  'badger',
  'bandicoot',
  'barnacle',
  'barracuda',
  'bat',
  'beagle',
  'bear',
  'beaver',
  'bee',
  'beetle',
  'bird',
  'bison',
  'bloodhound',
  'boar',
  'bobcat',
  'bonobo',
  'buffalo',
  'bulldog',
  'bullfrog',
  'butterfly',
  'buzzard',
  'camel',
  'cat',
  'caterpillar',
  'catfish',
  'cattle',
  'centipede',
  'chameleon',
  'cheetah',
  'chicken',
  'chihuahua',
  'chimpanzee',
  'chin',
  'chinchilla',
  'chipmunk',
  'civet',
  'clam',
  'cockroach',
  'coon',
  'coral',
  'corgi',
  'cougar',
  'cow',
  'coyote',
  'crab',
  'crane',
  'cuttlefish',
  'dachshund',
  'dalmatian',
  'dane',
  'deer',
  'devil',
  'dingo',
  'dodo',
  'dog',
  'dogfish',
  'dollar',
  'dolphin',
  'donkey',
  'dormouse',
  'dragon',
  'dragonfly',
  'duck',
  'dugong',
  'eagle',
  'earwig',
  'eel',
  'elephant',
  'emperor',
  'emu',
  'falcon',
  'ferret',
  'fish',
  'flamingo',
  'flounder',
  'fly',
  'forest',
  'fowl',
  'fox',
  'foxhound',
  'frog',
  'gar',
  'gecko',
  'gerbil',
  'gharial',
  'gibbon',
  'giraffe',
  'goat',
  'goose',
  'gopher',
  'gorilla',
  'grasshopper',
  'greyhound',
  'grouse',
  'guppy',
  'hamster',
  'hare',
  'hedgehog',
  'heron',
  'hippopotamus',
  'hornet',
  'horse',
  'hound',
  'hummingbird',
  'husky',
  'hyena',
  'hyrax',
  'ibis',
  'iguana',
  'impala',
  'insect',
  'jackal',
  'jaguar',
  'jellyfish',
  'kangaroo',
  'kingfisher',
  'kiwi',
  'koala',
  'kudu',
  'labradoodle',
  'ladybird',
  'lemming',
  'lemur',
  'leopard',
  'liger',
  'lion',
  'lionfish',
  'lizard',
  'llama',
  'lobster',
  'lynx',
  'macaque',
  'macaw',
  'magpie',
  'malamute',
  'maltese',
  'mammoth',
  'manatee',
  'mandrill',
  'markhor',
  'marmoset',
  'mastiff',
  'mayfly',
  'meerkat',
  'millipede',
  'mole',
  'mongoose',
  'monkey',
  'monster',
  'moorhen',
  'moose',
  'moth',
  'mouse',
  'mule',
  'newt',
  'nightingale',
  'ocelot',
  'octopus',
  'okapi',
  'orangutan',
  'ostrich',
  'otter',
  'owl',
  'oyster',
  'panda',
  'panther',
  'parrot',
  'peacock',
  'pelican',
  'penguin',
  'pheasant',
  'pig',
  'pike',
  'piranha',
  'platypus',
  'pointer',
  'poodle',
  'porcupine',
  'possum',
  'prawn',
  'puffin',
  'pug',
  'puma',
  'quail',
  'rabbit',
  'raccoon',
  'rat',
  'rattlesnake',
  'ray',
  'reindeer',
  'retriever',
  'rhinoceros',
  'robin',
  'rottweiler',
  'russel',
  'salamander',
  'scorpion',
  'seahorse',
  'seal',
  'shark',
  'sheep',
  'sheepdog',
  'shrew',
  'shrimp',
  'skater',
  'skunk',
  'sloth',
  'slug',
  'snail',
  'snake',
  'snowshoe',
  'spaniel',
  'sparrow',
  'spider',
  'sponge',
  'squid',
  'squirrel',
  'squirt',
  'starfish',
  'stingray',
  'stoat',
  'swan',
  'tapir',
  'tarantula',
  'termite',
  'terrier',
  'tiger',
  'toad',
  'tortoise',
  'toucan',
  'tuatara',
  'turkey',
  'turtle',
  'uakari',
  'urchin',
  'vole',
  'vulture',
  'wallaby',
  'walrus',
  'warthog',
  'wasp',
  'weasel',
  'whale',
  'whippet',
  'wildebeest',
  'wolf',
  'wolfhound',
  'wolverine',
  'wombat',
  'woodlouse',
  'woodpecker',
  'worm',
  'yak',
  'zebra',
  'zebu',
  'zonkey',
  'zorse',
]

export default function psudonymise(input: string): string {
  if (input != null && input.trim().length > 0) {
    const [adjSrc, nounSrc] = [input.slice(0, (input.length * 2) / 3), input.slice((input.length * 2) / 3)]
    const adj = adjectives[Math.abs(hashCode(adjSrc)) % adjectives.length]
    const noun = nouns[Math.abs(hashCode(nounSrc)) % nouns.length]
    return `${adj.charAt(0).toUpperCase() + adj.slice(1)} ${noun.charAt(0).toUpperCase() + noun.slice(1)}`
  }
  return ''
}
