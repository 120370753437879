import NoopMixpanelInstance from 'components/integrations/Mixpanel/NoopMixpanelInstance'
import { ModalsPathMap, SetModalParams } from 'components/molecules/Modal/ModalWrapper.types'
import { CommentChannelType } from 'components/organisms/NegotiationSidePanels/CommentChannel/CommentChannel.types'

export const noopMixpanelInstance = new NoopMixpanelInstance()

export const consts = {
  LOGIN: 'LOGIN (FE)',
  QUICK_START_NEGOTIATION: 'QUICK START BUTTON CLICKED',
  NEGOTIATION_CREATED: {
    'quick-start': 'NEGOTIATION CREATED (QUICK START)',
    'document-panel': 'NEGOTIATION CREATED (DOCUMENTS TAB)',
    'custodian-template': 'NEGOTIATION CREATED (CUSTODIAN TEMPLATE)',
  } satisfies Record<Required<SetModalParams<keyof ModalsPathMap>>['eventOrigin'] | 'custodian-template', string>,
  EXTERNAL_COMMENT_COVER_NOTE: 'EXTERNAL COMMENT (COVER NOTE)',
  DOCLEVEL_APPROVAL_APPROVED: 'DOCUMENT-LEVEL APPROVAL APPROVED',
  DOCLEVEL_APPROVAL_REJECTED: 'DOCUMENT-LEVEL APPROVAL REJECTED',
  ELECTION_APPROVAL_APPROVED: 'ELECTION APPROVAL APPROVED',
  ELECTION_APPROVAL_REJECTED: 'ELECTION APPROVAL REJECTED',
  ADD_COMMENT: {
    internal: 'INTERNAL COMMENT (NEGOTIATION VIEW)',
    external: 'EXTERNAL COMMENT (NEGOTIATION VIEW)',
    'internal-execution': 'INTERNAL COMMENT (REVIEW & SIGN PAGE)',
    execution: 'EXTERNAL COMMENT (REVIEW & SIGN PAGE)',
    moderator: 'MODERATOR COMMENT (NEGOTIATION VIEW)',
  } satisfies Record<CommentChannelType, string>,
  NEGOTIATION_EXECUTED: 'NEGOTIATION EXECUTED',
}

export default {}
