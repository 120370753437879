import { createContext, useContext } from 'react'

import { ModalContextState } from 'components/molecules/Modal/ModalWrapper.types'

export const ModalContext = createContext<ModalContextState>({
  open() {},
  close() {},
  updatePosition() {},
  getModalParams() {
    return null
  },
  subscribe() {
    return { unsubscribe() {} }
  },
})

export function useModalContext(): ModalContextState {
  return useContext(ModalContext)
}
