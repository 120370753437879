import { createSelector } from '@reduxjs/toolkit'
import get from 'lodash.get'

import {
  ComponentAnswer,
  isComponentElectionAnswers,
  isNestedAnswersComponentAnswer,
  isUmbrellaPIS,
  PartyAnswers,
  UmbrellaPrincipal,
} from 'types/Answers'
import { Negotiation } from 'types/Negotiation'
import { Preset } from 'types/Preset'

export const selectNegotiationState = createSelector(
  (result: { data?: Negotiation }) => result.data?.state,
  negotiationState => ({ negotiationState })
)

export const selectNestedAnswersSummaries = createSelector(
  (result: { data?: Negotiation }) => result.data?.nestedAnswersSummaries,
  nestedAnswersSummaries => ({ nestedAnswersSummaries: nestedAnswersSummaries ?? {} })
)

export const selectNestedAnswersSummariesFromTemplate = createSelector(
  (result: { data?: Preset }) => result.data?.nestedAnswersSummaries,
  nestedAnswersSummaries => ({ nestedAnswersSummaries: nestedAnswersSummaries ?? {} })
)

export function selectCurrentNestedPrincipalIdByNameCombiner(
  currentPartyAnswers: PartyAnswers,
  name: string
): { currentNestedPrincipalId: string | undefined } {
  const answer = get(currentPartyAnswers, name) as ComponentAnswer | undefined
  const currentNestedPrincipalId = isNestedAnswersComponentAnswer(answer) ? answer.nestedAnswersId : undefined
  return {
    currentNestedPrincipalId,
  }
}

export const selectCurrentNestedPrincipalIdByName = createSelector(
  [
    (result: { data?: Negotiation }) => result.data?.currentparty && result.data.answers[result.data.currentparty],
    (result: { data?: Negotiation }) => result.data?.counterparty && result.data.answers[result.data.counterparty],
    (_, name: string) => name,
  ],
  (currentPartyAnswers, counterPartyAnswers, name) => {
    const { currentNestedPrincipalId } = selectCurrentNestedPrincipalIdByNameCombiner(currentPartyAnswers ?? {}, name)
    const { currentNestedPrincipalId: currentNestedPrincipalIdCounterparty } =
      selectCurrentNestedPrincipalIdByNameCombiner(counterPartyAnswers ?? {}, name)

    return {
      currentNestedPrincipalId: currentNestedPrincipalId ?? currentNestedPrincipalIdCounterparty,
    }
  }
)

export const selectCurrentNestedPrincipalIdByNameFromTemplate = createSelector(
  [(result: { data?: Preset }) => result.data?.answers, (_, name: string) => name],
  (answers, name) => selectCurrentNestedPrincipalIdByNameCombiner(answers as PartyAnswers, name)
)

function selectPartyPrincipalsCombiner(answers: PartyAnswers | undefined) {
  const umbrellaElectionId = 'umbrella_agreement_and_principal_identification'

  const umbrellaElectionAnswers = answers?.[umbrellaElectionId]
  const pisComponentAnswers =
    (isComponentElectionAnswers(umbrellaElectionAnswers) &&
      get(umbrellaElectionAnswers, 'principal_identification_schedule.value', undefined)) ||
    undefined

  return {
    principals: (isUmbrellaPIS(pisComponentAnswers) && pisComponentAnswers) || [],
  }
}

export const selectCurrentPartyPrincipals = createSelector(
  [(result: { data?: Negotiation }) => result.data?.currentparty && result.data.answers[result.data.currentparty]],
  (answers): { currentPartyPrincipals: UmbrellaPrincipal[] } => ({
    currentPartyPrincipals: selectPartyPrincipalsCombiner(answers).principals,
  })
)

export const selectCounterPartyPrincipals = createSelector(
  [(result: { data?: Negotiation }) => result.data?.counterparty && result.data.answers[result.data.counterparty]],
  answers => ({
    counterPartyPrincipals: selectPartyPrincipalsCombiner(answers).principals,
  })
)

export const selectCounterpartyHasChangedNestedAnswers = createSelector(
  (result: { data?: Negotiation }) => result.data?.counterpartyHasChangedNestedAnswers,
  cpChangedAnswers => ({ counterpartyHasChangedNestedAnswers: cpChangedAnswers ?? false })
)
