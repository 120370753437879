'use client'

import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'

import mixpanel from 'mixpanel-browser'
import { usePathname, useSearchParams } from 'next/navigation'
import { object, string } from 'yup'

import { useHubSpot } from 'components/integrations/HubSpot'
import { useAppSelector } from 'redesignStore'
import pseudonymise from 'utils/pseudonymise'

import { noopMixpanelInstance } from './Mixpanel.consts'
import { MixpanelContext } from './Mixpanel.context'
import { MixpanelAPI } from './Mixpanel.types'
import MixpanelInstance from './MixpanelInstance'

const mixpanelConfigSchema = object({
  enabled: string(),
  token: string(),
}).json()

const mixpanelInfo = process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_INFO
const mixpanelConfig = mixpanelConfigSchema.validateSync(mixpanelInfo)
const isEnvEnabled = mixpanelConfig.enabled
const projectToken = mixpanelConfig.token
const env = process.env.NEXT_PUBLIC_ENVIRONMENT_NAME

const isMixpanelEnabled = isEnvEnabled === 'true' && projectToken

export default function MixpanelProvider({ children }: { children: ReactNode }) {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const [Mixpanel, setMixpanel] = useState<MixpanelAPI>(noopMixpanelInstance)
  const [isInitialised, setIsInitialised] = useState(false)

  const { cookieConsent } = useHubSpot()
  const optIn = cookieConsent?.categories.analytics ?? false

  const userId = useAppSelector(state => state.context.userId)
  const loggedInAccount = useAppSelector(state => state.context.account)
  const loggedIn = !!userId
  const recordedUserId = useRef<string | null>(null)

  useEffect(() => {
    // Only enable Mixpanel when it's enabled in config and the user is opted in to analytics cookies
    if (
      !isInitialised &&
      isMixpanelEnabled &&
      optIn // Opt-ed in to analytics cookies
    ) {
      mixpanel.init(projectToken, {
        debug: env.startsWith('local'),
        autotrack: true,
        ip: false,
        save_referrer: false,
        // track_pageview: true /* enables tracking for all pages, not needed as we do this manually */
      })
      setMixpanel(new MixpanelInstance(mixpanel, env))
      setIsInitialised(true)
    }
  }, [optIn, isInitialised, Mixpanel, setMixpanel, setIsInitialised, loggedIn, userId])

  // When logged in state changes make sure Mixpanel knows about it
  useEffect(() => {
    if (isInitialised && isMixpanelEnabled && optIn && userId !== recordedUserId.current) {
      if (loggedIn && loggedInAccount) {
        Mixpanel.identify(pseudonymise(userId))
        recordedUserId.current = userId
        Mixpanel.trackEvent.LOGIN(loggedInAccount)
      } else if (!loggedIn) {
        // Logout
        Mixpanel.identify('') // For some reason reset doesn't seem to properly remove the identifier
        Mixpanel.reset()
        recordedUserId.current = null
      }
    }
  }, [Mixpanel, loggedIn, isInitialised, userId, optIn, loggedInAccount])

  // When the URL changes, record a page view
  useEffect(() => {
    if (pathname && isInitialised && optIn && loggedIn) {
      let searchString = searchParams?.toString()
      if (pathname === '/callback' && searchParams?.has('code')) {
        const mutableSearchParams = new URLSearchParams(Array.from(searchParams.entries()))
        mutableSearchParams.set('code', 'secret')
        searchString = mutableSearchParams.toString()
      }

      Mixpanel.trackView(pathname, searchString)
    }
  }, [Mixpanel, pathname, searchParams, isInitialised, userId, loggedIn, optIn])

  const childContext: MixpanelAPI = useMemo(() => Mixpanel, [Mixpanel])

  return <MixpanelContext.Provider value={childContext}>{children}</MixpanelContext.Provider>
}
