import { createSelector } from '@reduxjs/toolkit'
import { compareAsc } from 'date-fns/compareAsc'
import get from 'lodash.get'

import {
  CommentsByChannel,
  CommentsByGroupType,
  DocPreviewComment,
  ElectionComment,
  GeneralComment,
} from 'components/organisms/NegotiationSidePanels'
import { NestedAnswersResponse } from 'components/organisms/UmbrellaElection/components/UmbrellaModal/UmbrellaOverviewChannel/UmbrellaOverviewChannel.types'
import { selectNegotiationState } from 'components/organisms/UmbrellaElection/UmbrellaElection.selectors'
import { VisibleElectionData } from 'hooks/useVisibleElectionData'
import { PartyAnswers } from 'types/Answers'
import { AnswerSummary, isEstablishedParty, Negotiation, PartyPosition } from 'types/Negotiation'
import { TimelineUser } from 'types/Timeline'
import { ProfileColour } from 'types/UserContext'
import { isTripartiteStage1WorkflowType, isTripartiteStage2WorkflowType } from 'utils/negotiation/workflow'

export const emptySummary: AnswerSummary = {
  agreed: false,
  completed: false,
  matched: false,
  percentComplete: 0,
  percentMatched: 0,
  totalVisible: [],
  totalCompleted: [],
  totalMatched: [],
  amend: false,
  cpHasAnswered: false,
}
export const selectAllElectionSummaries = createSelector(
  [(result: { data?: Negotiation }) => result.data?.answerSummaries],
  answerSummaries => ({ answerSummaries: answerSummaries ?? {} })
)

export const selectAllNestedElectionSummaries = createSelector(
  [(result: { data?: NestedAnswersResponse }) => result.data?.answerSummaries],
  answerSummaries => ({ answerSummaries })
)

export const selectAvailableActions = createSelector(
  (result: { data?: Negotiation }) => result.data?.availableActions,
  availableActions => ({ availableActions })
)

export const selectLockedState = createSelector(
  [
    (result: { data?: Negotiation }) => result.data?.activeUser,
    (result: { data?: Negotiation }) => result.data?.isTurn,
    selectNegotiationState,
    selectAvailableActions,
    (result: { data?: Negotiation }) => result.data?.dates.lastModified,
    (result: { data?: Negotiation }) => result.data?.dates.stateReached,
  ],
  (activeUser, isTurn, { negotiationState }, { availableActions }, lastModified, stateReached) => {
    const isLocked =
      !activeUser?.isCurrentUser &&
      isTurn &&
      negotiationState &&
      ![
        'draftElectionApprovalRequired',
        'receiverDraftElectionApprovalRequired',
        'electionApprovalRequired',
        'executionAgreed',
      ].includes(negotiationState) &&
      (availableActions?.assignUser || availableActions?.assignToMe)

    return {
      isLockedNegotiation: isLocked ?? false,
      lastModified: isLocked ? lastModified || stateReached : undefined,
      activeUser: isLocked ? activeUser : undefined,
    }
  }
)

export const selectElectionSummary = createSelector(
  [selectAllElectionSummaries, (_result: { data?: Negotiation }, electionId: string) => electionId],
  ({ answerSummaries: summaries }, electionId) => ({ answerSummary: get(summaries, electionId, emptySummary) })
)

export const selectNestedElectionSummary = createSelector(
  [
    (result: { data?: NestedAnswersResponse }) => selectAllNestedElectionSummaries(result),
    (_, electionId: string) => electionId,
  ],
  ({ answerSummaries: summaries }, electionId) => ({ answerSummary: get(summaries, electionId, emptySummary) })
)

export const selectVisibleElectionData = createSelector(
  [
    (result: { data?: Negotiation }) => result.data?.requestingPartyIsInitiator,
    (result: { data?: Negotiation }) => result.data?.reservedElectionIds,
    (result: { data?: Negotiation }) => result.data?.phase2ReservedElectionIds,
    (result: { data?: Negotiation }) => result.data?.forkedFrom,
    (result: { data?: Negotiation }) => result.data?.workflowType,
    (result: { data?: Negotiation }) => result.data?.requester,
    (result: { data?: Negotiation }) => result.data?.document.skipProposeDatePhase,
  ],

  (
    requestingPartyIsInitiator,
    reservedElectionIds,
    phase2ReservedElectionIds,
    forkedFrom,
    workflowType,
    requester,
    skipProposeDatePhase
  ): VisibleElectionData => ({
    isAmendAndRestatement: !!forkedFrom?.length,
    isTripartyPhase1: !!workflowType && isTripartiteStage1WorkflowType(workflowType),
    isPhase2: !!workflowType && isTripartiteStage2WorkflowType(workflowType),
    skipProposeDatePhase: !!skipProposeDatePhase,
    isModerator: requester?.position === 'moderator',
    isInitiator: !!requestingPartyIsInitiator,
    reservedElectionIds: reservedElectionIds ?? [],
    phase2ReservedElectionIds: phase2ReservedElectionIds ?? [],
  })
)

const getLatestAuthors = (comments: Array<ElectionComment | GeneralComment | DocPreviewComment>) =>
  comments
    .reduce(
      (acc: TimelineUser[], { user }) => (acc.find(u => u.firstName === user.firstName) ? acc : [...acc, user]),
      []
    )
    .slice(-2)

const getSortedComments = ({
  internal = [],
  external = [],
  moderator = [],
}: CommentsByChannel<ElectionComment | GeneralComment | DocPreviewComment>) =>
  [...internal, ...external, ...moderator].sort(({ created: createdA }, { created: createdB }) =>
    compareAsc(new Date(createdA), new Date(createdB))
  )

export const getCommentsData = (comments: CommentsByChannel<ElectionComment | GeneralComment | DocPreviewComment>) => {
  const sortedComments = getSortedComments(comments)
  const unreadComments = sortedComments.filter(({ isNewForRecipient }) => isNewForRecipient)
  const latestAuthors = getLatestAuthors(sortedComments)
  const initials = latestAuthors.map(author => author.firstName)
  const profileColours = latestAuthors
    .map(author => author.profileColour)
    .filter((item): item is ProfileColour => !!item)
  return {
    initials,
    profileColours,
    unread: unreadComments.length,
  }
}

const emptyComments: CommentsByChannel<ElectionComment> = {}
export const electionCommentsSelector = createSelector(
  [(state: CommentsByGroupType | undefined, electionId: string) => state?.election?.[electionId] ?? emptyComments],
  commentsData => (commentsData ? getCommentsData(commentsData) : { initials: [], unread: 0 })
)

const emptyAnswers: PartyAnswers = {}
export const selectCounterpartyAnswers = createSelector(
  [(result: { data?: Negotiation }) => result.data?.counterparty && result.data.answers[result.data.counterparty]],
  answers => ({ counterpartyAnswers: answers ?? emptyAnswers })
)

export const selectDocumentVersion = createSelector(
  [
    (result: { data?: Negotiation }) => result.data?.document.id,
    (result: { data?: Negotiation }) => result.data?.document.version,
  ],
  (documentId, documentVersion) => ({ documentId, documentVersion })
)

export const selectDefaultsSet = createSelector(
  [
    (result: { data?: Negotiation }) => result.data?.defaultsSet,
    (result: { data?: Negotiation }) => result.data?.availableActions.edit,
  ],
  (defaultsSet, canEdit) => ({ defaultsSet, canEdit })
)

export const selectNestedAnswersForParty = createSelector(
  [(result: { data?: NestedAnswersResponse }, party: PartyPosition) => result.data?.[party]],
  answers => ({ answers: answers ?? emptyAnswers })
)

export const selectCurrentPartyAnswers = createSelector(
  [(result: { data?: Negotiation }) => result.data?.currentparty && result.data.answers[result.data.currentparty]],
  answers => ({ answers: answers ?? emptyAnswers })
)

export const selectCounterpartyId = createSelector(
  [
    (result: { data?: Negotiation }) => result.data?.parties,
    (result: { data?: Negotiation }) => result.data?.counterparty,
  ],
  (parties, counterparty) => {
    const cp = (parties ?? []).find(party => party.position === counterparty)

    return { partyId: cp && isEstablishedParty(cp) ? cp.partyId : undefined }
  }
)

export const selectDocumentAvailableActions = createSelector(
  (result: { data?: Negotiation }) => result.data?.document.availableActions,
  documentAvailableActions => ({ documentAvailableActions })
)

export const selectWorkflowType = createSelector(
  (result: { data?: Negotiation }) => result.data?.workflowType,
  workflowType => ({ workflowType })
)

export const selectCanChangeActiveUser = createSelector(
  [
    (result: { data?: Negotiation }) => result.data?.activeUser,
    (result: { data?: Negotiation }) => result.data?.availableActions,
  ],
  (activeUser, availableActions) => {
    const canChangeActiveUser = !activeUser?.isCurrentUser && availableActions?.assignToMe
    return { canChangeActiveUser }
  }
)
