// noinspection SuspiciousTypeOfGuard

export default function hashCode(input: string): number {
  let str = input

  // Handle nasty edge cases
  if (typeof input === 'undefined') {
    str = ''
  } else if (typeof input !== 'string') {
    try {
      str = JSON.stringify(input)
    } catch {
      str = `${input}`
    }
  }

  let hash = 0
  if (str.length === 0) {
    return hash
  }

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char
    // eslint-disable-next-line no-bitwise
    hash &= hash // Convert to 32bit integer
  }
  return hash
}
