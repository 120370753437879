import Stack, { StackProps } from '@mui/material/Stack'
import { styled, alpha } from '@mui/material/styles'

export const partySelectorModalWidth = 743
export const pointerZIndex = 1

const BaseStyledPartyContainer = styled(Stack, { shouldForwardProp: prop => prop !== 'showPointer' })<
  StackProps & { showPointer?: boolean }
>(({ theme, showPointer = true }) => ({
  background: theme.palette.lightSurface.main,
  minWidth: partySelectorModalWidth,
  maxWidth: partySelectorModalWidth,
  borderRadius: '30px',
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  padding: theme.spacing(1.5, 1.25, 1.75, 1.25),
  boxShadow: `0px 1px 4px ${alpha(theme.palette.common.black, 0.25)}`,

  ...(showPointer && {
    '&:after': {
      content: '" "',
      width: '36px',
      height: '36px',
      backgroundColor: theme.palette.lightSurface.main,
      position: 'absolute',
      top: '-12px',
      transform: 'rotate(45deg)',
      zIndex: pointerZIndex,
    },
  }),
}))

export const StyledCurrentPartyWrapper = styled(BaseStyledPartyContainer)(({ theme }) => ({
  '&:after': {
    left: '140px',
  },
}))

export const StyledCounterPartyWrapper = styled(BaseStyledPartyContainer)(({ theme }) => ({
  '&:after': {
    right: '140px',
  },
}))
