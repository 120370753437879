import { LinearProgressProps } from '@mui/material/LinearProgress'

import { emptySummary } from 'redesignStore/api/negotiationApi/negotiationApi.selectors'
import { NegotiationPermittedAdvisor, TemplatePermittedAdvisor } from 'types/Advisor'
import { AnswerSummary, ElectionOverviewStatus, ElectionProgressStatus, NestedAnswersSummary } from 'types/Negotiation'
import { PresetNestedAnswersSummary } from 'types/Preset'
import { ElectionColor } from 'utils/electionProgress/ElectionColor'
import { WeightedElectionColorPair } from 'utils/electionProgress/WeightedElectionColorPair'
import { WeightedElectionColorSet } from 'utils/electionProgress/WeightedElectionColorSet'

export const getElectionNumber = (index: number) => index?.toString().padStart(2, '0') || ''

const status = (color: ElectionColor): ElectionOverviewStatus => {
  switch (color) {
    case 'success':
      return ElectionOverviewStatus.Agreed
    case 'warning':
      return ElectionOverviewStatus.NotAgreed
    case 'grey':
      return ElectionOverviewStatus.Complete
    case 'transparent':
      return ElectionOverviewStatus.Incomplete
  }
}
const electionColorToTheme = (color: ElectionColor): Required<LinearProgressProps>['color'] => {
  switch (color) {
    case 'success':
      return 'success'
    case 'warning':
      return 'warning'
    case 'grey':
      return 'secondary'
    case 'transparent':
      return 'transparent'
  }
}

export function progressStatusFromSummary(
  answerSummary: AnswerSummary = emptySummary,
  isTemplate: boolean
): ElectionProgressStatus {
  const colorSet = WeightedElectionColorSet.calculateFromAnswerSummary(answerSummary, isTemplate)
  const colorPair = WeightedElectionColorPair.calculate(colorSet)
  const electionColor = WeightedElectionColorPair.getSummaryColor(colorPair)

  return {
    status: status(electionColor),
    left: electionColorToTheme(colorPair.left),
    right: electionColorToTheme(colorPair.right),
    progress: colorPair.progress * 100,
  }
}

export function progressStatusFromNestedAnswersSummaries<T extends NestedAnswersSummary | PresetNestedAnswersSummary>(
  summaries: T[]
): ElectionProgressStatus {
  const colorSet = WeightedElectionColorSet.calculateFromNestedAnswersSummaries(summaries)
  const colorPair = WeightedElectionColorPair.calculate(colorSet)
  const electionColor = WeightedElectionColorPair.getSummaryColor(colorPair)

  return {
    status: status(electionColor),
    left: electionColorToTheme(colorPair.left),
    right: electionColorToTheme(colorPair.right),
    progress: colorPair.progress * 100,
  }
}

export function getShowShareWithAdvisorIcon(
  isAdvisor: boolean,
  isInClientWorkspace: boolean,
  canManageAdvisorRelationships: boolean | undefined,
  permittedAdvisors: NegotiationPermittedAdvisor[] | TemplatePermittedAdvisor[] | undefined
) {
  if (isAdvisor) return false
  if (isInClientWorkspace) return true
  return canManageAdvisorRelationships ? true : !!permittedAdvisors?.length
}

export const incompleteReservedElectionsError = 'negotiation.business_logic_error.incomplete_reserved_elections'
export const incompleteOfflineElectionsError = 'negotiation.business_logic_error.incomplete_negotiation'

export const negotiationBodyContainerMargin = 16 // px
