'use client'

import React from 'react'

import { Provider } from 'react-redux'

import { HubSpotProvider } from 'components/integrations/HubSpot'
import { MatomoProvider } from 'components/integrations/Matomo'
import { MixpanelProvider } from 'components/integrations/Mixpanel'
import store from 'redesignStore'
import { RedesignThemeProvider } from 'theme/redesign'

export default function RedesignProviders({ children }: { children: React.ReactNode }) {
  return (
    <Provider store={store}>
      <RedesignThemeProvider>
        <HubSpotProvider>
          <MatomoProvider>
            <MixpanelProvider>{children}</MixpanelProvider>
          </MatomoProvider>
        </HubSpotProvider>
      </RedesignThemeProvider>
    </Provider>
  )
}
