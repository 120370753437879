import get from 'lodash.get'
import isPlainObject from 'lodash.isplainobject'

import { DataEditorComponentTable } from 'components/molecules/RichTextField/proseMirror/plugins/dataEditor'

type NonEmptyArray<T> = [T, ...T[]]

export type SingleComponentAnswer = string

export function isSingleComponentAnswer(answer: ComponentAnswer | undefined): answer is SingleComponentAnswer {
  return typeof answer === 'string'
}

export type TabularComponentAnswer = {
  table: string
  data: Partial<{
    [key: string]: string | number | boolean
  }>[]
}

export function isTabularComponentAnswer(answer: ComponentAnswer | undefined): answer is TabularComponentAnswer {
  return (
    typeof answer === 'object' &&
    isPlainObject(answer) &&
    'table' in answer &&
    'data' in answer &&
    typeof answer.data === 'object' &&
    Array.isArray(answer.data)
  )
}

export type DataEditorComponentAnswer = {
  html: string
  data: Array<DataEditorComponentTable>
}

export function isDataEditorComponentAnswer(answer: ComponentAnswer | undefined): answer is DataEditorComponentAnswer {
  return (
    typeof answer === 'object' &&
    isPlainObject(answer) &&
    'html' in answer &&
    'data' in answer &&
    typeof answer.data === 'object' &&
    Array.isArray(answer.data)
  )
}

export type MultipleComponentAnswer = string[]

// If it's an empty array then we can't guarantee the type because there's no information to go off
export function isNonEmptyMultipleComponentAnswer(
  answer: ComponentAnswer | undefined
): answer is MultipleComponentAnswer & NonEmptyArray<MultipleComponentAnswer[number]> {
  return typeof answer === 'object' && Array.isArray(answer) && typeof answer.at(0) === 'string'
}

export function isMaybeMultipleComponentAnswer(answer: ComponentAnswer | undefined): answer is MultipleComponentAnswer {
  return typeof answer === 'object' && Array.isArray(answer) && !isNonEmptyRepeatingGroupComponentAnswer(answer)
}

export type RepeatingGroupComponentAnswer = { sys_row_id?: SingleComponentAnswer } & ComponentElectionAnswers
export type RepeatingGroupComponentAnswers = ({ sys_row_id?: SingleComponentAnswer } & ComponentElectionAnswers)[]

// If it's an empty array then we can't guarantee the type because there's no information to go off
export function isNonEmptyRepeatingGroupComponentAnswer(
  answer: ComponentAnswer | undefined
): answer is RepeatingGroupComponentAnswers & NonEmptyArray<RepeatingGroupComponentAnswers[number]> {
  return typeof answer === 'object' && Array.isArray(answer) && typeof answer.at(0) === 'object'
}

export function isMaybeRepeatingGroupComponentAnswer(
  answer: ComponentAnswer | undefined
): answer is RepeatingGroupComponentAnswers {
  return typeof answer === 'object' && Array.isArray(answer) && !isNonEmptyMultipleComponentAnswer(answer)
}

export type NestedAnswersComponentAnswer = {
  nestedAnswersId: string
  answers: PartyAnswers
}

export function isNestedAnswersComponentAnswer(
  answer: ComponentAnswer | undefined
): answer is NestedAnswersComponentAnswer {
  return (
    typeof answer === 'object' &&
    isPlainObject(answer) &&
    'nestedAnswersId' in answer &&
    'answers' in answer &&
    typeof answer.answers === 'object' &&
    isPlainObject(answer.answers)
  )
}

export type ComponentAnswer =
  | SingleComponentAnswer
  | TabularComponentAnswer
  | DataEditorComponentAnswer
  | MultipleComponentAnswer
  | RepeatingGroupComponentAnswers
  | NestedAnswersComponentAnswer
  | MultiComponentAnswer

export type ComponentElectionAnswers = Partial<{ [componentId: string]: ComponentAnswer }>
export type TextAmendmentElectionAnswers = { textAmendment: string }

export type ElectionAnswers = ComponentElectionAnswers | TextAmendmentElectionAnswers

export function isComponentElectionAnswers(answers: ElectionAnswers | undefined): answers is ComponentElectionAnswers {
  return typeof answers === 'object' && isPlainObject(answers) && !isTextAmendment(answers)
}

export function isTextAmendment(answers: ElectionAnswers | undefined): answers is TextAmendmentElectionAnswers {
  return typeof answers === 'object' && 'textAmendment' in answers
}

// This isn't a defined type in its own right, it is a configuration convention
export type UmbrellaPrincipal = {
  principal_name?: SingleComponentAnswer
  lei?: SingleComponentAnswer
  custom_id?: SingleComponentAnswer
  principal_elections?: NestedAnswersComponentAnswer
  isPendingRemoval?: boolean
} & RepeatingGroupComponentAnswers[number] // Allow any additional custom properties

export function isUmbrellaPIS(answer: ComponentAnswer | undefined): answer is UmbrellaPrincipal[] {
  return (
    isMaybeRepeatingGroupComponentAnswer(answer) &&
    answer.every(
      (principal): principal is UmbrellaPrincipal =>
        (!('principal_name' in principal) || typeof principal.principal_name === 'string') &&
        (!('lei' in principal) || typeof principal.lei === 'string') &&
        (!('custom_id' in principal) || typeof principal.custom_id === 'string') &&
        (!('principal_elections' in principal) ||
          (!!principal.principal_elections && isNestedAnswersComponentAnswer(principal.principal_elections)))
    )
  )
}

export type PartyAnswers = Partial<{
  [electionId: string]: ElectionAnswers
}>

export type AnswersAtPathType<TName extends string> = TName extends ''
  ? PartyAnswers
  : TName extends `${string}.${string}`
    ? ComponentAnswer
    : ElectionAnswers

export function getAnswersAtPath<TName extends string>(
  answers: PartyAnswers,
  name: TName,
  defaultValue?: AnswersAtPathType<TName>
): AnswersAtPathType<TName> | undefined {
  return get(answers, name, defaultValue) as AnswersAtPathType<TName>
}

export function isNonEmptyAnswer(answer: ComponentAnswer | undefined): boolean {
  return (
    (isSingleComponentAnswer(answer) && answer.length > 0) ||
    (isTabularComponentAnswer(answer) && answer.table.length > 0) ||
    (isDataEditorComponentAnswer(answer) && answer.html.length > 0) ||
    isNonEmptyMultipleComponentAnswer(answer) ||
    isNonEmptyRepeatingGroupComponentAnswer(answer) ||
    isNestedAnswersComponentAnswer(answer)
  )
}

export function isEmptyAnswer(answer: ComponentAnswer | undefined): boolean {
  return !isNonEmptyAnswer(answer)
}

export type MultiField = Partial<{
  sys_row_id: string
  isPendingRemoval?: boolean
  [componentId: string]: ComponentAnswer | boolean | undefined
}>

export type MultiComponentAnswer = {
  value: RepeatingGroupComponentAnswers
  isPendingReorder?: boolean
}

export function isMultiComponentAnswer(answer: ComponentAnswer | undefined): answer is MultiComponentAnswer {
  return (
    typeof answer === 'object' &&
    isPlainObject(answer) &&
    'value' in answer &&
    isNonEmptyRepeatingGroupComponentAnswer(answer.value)
  )
}
