import { LinearProgressProps } from '@mui/material/LinearProgress'

import { ActivityEventResponse } from 'components/organisms/NegotiationSidePanels'
import { ApprovalStatus } from 'components/organisms/NegotiationSidePanels/ApprovalChannel/ApprovalChannel.types'
import { SigningMode } from 'components/organisms/ReviewAndSign'
import { NestedAnswersResponse } from 'components/organisms/UmbrellaElection/components/UmbrellaModal/UmbrellaOverviewChannel/UmbrellaOverviewChannel.types'
import { NegotiationPermittedAdvisor } from 'types/Advisor'
import { PartyAnswers } from 'types/Answers'
import { Document, DocumentSchema } from 'types/Document'
import { NegotiationWorkflowType } from 'types/Negotiations'
import { RenderedTemplateResponse } from 'types/Templates'
import { EntityId } from 'types/User'
import { ProfileColour } from 'types/UserContext'

export type PartyPosition = 'partyA' | 'partyB' | 'moderator'

export const partyPositionLabel = (position: PartyPosition): 'Party A' | 'Party B' | 'Moderator' => {
  switch (position) {
    case 'partyA':
      return 'Party A'
    case 'partyB':
      return 'Party B'
    case 'moderator':
      return 'Moderator'
  }
}

export enum NegotiationStatus {
  draft = 'preparingFirstDraft',
  negotiating = 'negotiating',
  confirming = 'confirming',
  proposingDate = 'proposingDate',
  pendingExecution = 'pendingExecution',
  executed = 'executed',
  cancelled = 'cancelled',
  confirmedTemplate = 'confirmedTemplate',
  sendingFailed = 'sendingFailed',
}

export enum NegotiationState {
  Draft = 'draft',
  Inviting = 'inviting',
  ReceiverDraft = 'receiverDraft',
  Amending = 'amending',
  Confirmed = 'confirmed',
  ConfirmedTemplate = 'confirmedTemplate',
  Agreed = 'agreed',
  ExecutionAmending = 'executionAmending',
  ExecutionAgreed = 'executionAgreed',
  Executed = 'executed',
  Cancelled = 'cancelled',
  PendingSendToCp = 'pendingSendToCp',
}

export enum NegotiationStatusLabel {
  Draft = 'Draft',
  SendingFailed = 'Sending Failed',
  Sent = 'Sent',
  Received = 'Received',
  Negotiation = 'Negotiation',
  AwaitingCounterpartyAction = 'Awaiting counterparty action',
  Confirmed = 'Confirmed',
  Execution = 'Execution',
  SetDate = 'Set Date',
  ProposeDate = 'Propose Date',
  Completed = 'Completed',
  SentToCustodianForApproval = 'Sent to Custodian for Approval',
  CustodianTemplate = 'Custodian Template',
  Cancelled = 'Cancelled',
  SendingToCounterparty = 'Sending To Counterparty',
  DocumentApprovalRequired = 'Document Approval required',
  ElectionApprovalRequired = 'Election Approval required',
}

export type NegotiationSummary = {
  id: string
  subAccountId: string
  document: Document
  preset?: { id?: string; name: string; approvedAt?: string }
  presetSwapped?: boolean
  state: NegotiationState
  statusLabel: NegotiationStatusLabel
  isTurn: boolean
  currentparty: PartyPosition
  counterparty: PartyPosition
  partyA?: Entity
  partyB?: Entity
  moderator?: Entity
  draftCounterparties: Counterparty[]
  generalCoverNote?: string
  activeUser?: ActiveUser
  version: string
  totalElections: number
  matchingElections?: number
  dates: NegotiationDates
  chaserEmail?: ChaserEmailInfo
  customFields: NegotiationCustomFields
  documentApprovalState?: ApprovalState
  externalDocumentApprovalState?: ApprovalState
  electionApprovalState?: ApprovalState
  electionApprovalCounts?: { total: number; pending: number; totalForMe: number; pendingForMe: number }
  health: string
  initiator: Initiator
  receivers: Receiver[]
  requestingPartyIsInitiator: boolean
  requester: Requester
  parent?: NegotiationParent
  groupId?: string
  groupName?: string
  bulkSetId?: string
  errorFromLastJob?: JobError
  parties: Party[]
  permittedAdvisorAccounts?: string[]
  workflowType: NegotiationWorkflowType
  markedAsFavourite: boolean
  forkedFrom: NegotiationSummary[]
  forkedTo: NegotiationSummary[]
  progressPercent?: number
  progressStatus: NegotiationStatus
  forkVersion?: string
}

export type Negotiation = NegotiationSummary & {
  answers: NegotiationAnswers
  availableActions: NegotiationAvailableActions
  availableChannels: NegotiationAvailableChannels
  counterpartyHasValidElections: boolean
  counterpartyHasChangedNestedAnswers: boolean
  document: Document
  documentApproval?: Approval
  externalDocumentApproval?: Approval
  electionApprovals: { [electionId: string]: Approval }
  electionsToApprove: string[]
  fileExtensionsSupported: string[]
  health: string
  groupMemberSummaries: NegotiationSummary[]
  nestedAnswersSummaries: { [nestedAnswersId: string]: NestedAnswersSummary }
  nestedAnswerFullSummary?: NestedAnswersResponse
  nestedAnswerFullSummaries?: { [nestedAnswersId: string]: NestedAnswersResponse }
  reservableElectionIds: string[]
  reservedElectionIds: string[]
  permittedAdvisors?: NegotiationPermittedAdvisor[]
  previouslyReservedElectionIds: string[]
  previouslyReservedElectionIdsPhase2: string[]
  phase2ReservedElectionIds?: string[]
  phase2PreviouslyReservedElectionIds?: string[]
  forkDate?: string
  supportsESignature: boolean
  preview?: RenderedTemplateResponse
  progressPercent?: number
  defaultsSet: boolean
  answerSummaries: { [electionId: string]: AnswerSummary }
  timelineSummary: TimelineSummary
  timeline?: ActivityEventResponse[]
  signingMode?: SigningMode
  cancelledBy?: CancelledBy
}

export type UploadedFileNameWithNegotiation = {
  fileName: string
  negotiation: Negotiation
}

export type TimelineSummary = {
  totalActivities: number
  totalComments: number
  totalUnreadActivities: number
  totalUnreadComments: number
  totalActivitiesWithoutComments: number
  totalUnreadActivitiesWithoutComments: number
}

export type CancelledBy = {
  requestingParty: boolean
  userFullName: string
}

export type NegotiationAnswers = {
  [Party in PartyPosition]?: PartyAnswers
}

export type NegotiationDates = {
  started: string
  stateReached: string
  execution?: string
  invitationSent?: string
  invitationAccepted?: string
  lastModified?: string
  lastTurnSwap?: string
  lastNotification?: string
  nextNotificationAfter?: string
  lastNotificationOfDraft?: string
  nextNotificationOfDraftAfter?: string
  forkDate?: string
  previousForkDates: string[]
  lastFinalApproval?: string
  lastElectionApproval?: string
  lastExternalDocumentApproval?: string
  proposedExecution?: string
}

type ConfirmedTemplateParentDto = {
  type: 'ConfirmedTemplateParentDto'
  negotiationId: string
  availableActions: NegotiationAvailableActions
  confirmedAt?: string
}
export type NegotiationParent = ConfirmedTemplateParentDto

export type NegotiationAvailableActions = {
  view: boolean
  view_reason?: string
  edit: boolean
  edit_reason?: string
  assignUser: boolean
  assignUser_reason?: string
  assignToMe: boolean
  assignToMe_reason?: string
  setPreset: boolean
  setPreset_reason?: string
  setMultipleCounterparties: boolean
  setMultipleCounterparties_reason?: string
  setCounterparty: boolean
  setCounterparty_reason?: string
  setCounterpartyEntity: boolean
  setCounterpartyEntity_reason?: string
  sendToCounterparty: boolean
  sendToCounterparty_reason?: string
  changeSigningModeESign: boolean
  changeSigningModeESign_reason?: boolean
  changeSigningModeOffline: boolean
  changeSigningModeOffline_reason?: boolean
  confirm: boolean
  confirm_reason?: string
  confirmExecutionVersion: boolean
  confirmExecutionVersion_reason?: string
  downloadDocument: boolean
  downloadDocument_reason?: string
  downloadCounterpartyDocument: boolean
  downloadCounterpartyDocument_reason?: string
  cancel: boolean
  cancel_reason?: string
  setExecutionDate: boolean
  setExecutionDate_reason?: string
  revertToAmending: boolean
  revertToAmending_reason?: string
  revertToExecutionAmending: boolean
  revertToExecutionAmending_reason?: string
  approveDocument: boolean
  approveDocument_reason?: string
  rejectDocument: boolean
  rejectDocument_reason?: string
  sendForApproval: boolean
  sendForApproval_reason?: string
  approveDocumentOverride: boolean
  approveDocumentOverride_reason?: string
  rejectDocumentOverride: boolean
  rejectDocumentOverride_reason?: string
  approveElectionOverride: boolean
  approveElectionOverride_reason?: string
  rejectElectionOverride: boolean
  rejectElectionOverride_reason?: string
  setElectionApprovals: boolean
  setElectionApprovals_reason?: string
  setDocumentApprovals: boolean
  setDocumentApprovals_reason?: string
  uploadDeleteSignature: boolean
  uploadDeleteSignature_reason?: string
  sendChaserEmails: boolean
  sendChaserEmails_reason?: string
  setInitiatorEntity: boolean
  setInitiatorEntity_reason?: string
  canInvite: boolean
  canInvite_reason?: string
  markAsOffline: boolean
  markAsOffline_reason?: string
  markAsFinal: boolean
  markAsFinal_reason?: string
  notifyOfDraft: boolean
  notifyOfDraft_reason?: string
  uploadDeleteAuxiliaryDocument: boolean
  uploadDeleteAuxiliaryDocument_reason?: string
  fork: boolean
  fork_reason?: string
  uploadDeleteExecutedVersion: boolean
  uploadDeleteExecutedVersion_reason?: string
  markElectionReserved: boolean
  markElectionReserved_reason?: string
  unmarkElectionReserved: boolean
  unmarkElectionReserved_reason?: string
  upgradeSchema: boolean
  upgradeSchema_reason?: string
  updateCustomFields: boolean
  updateCustomFields_reason?: string
}

export type NegotiationAvailableChannels = {
  internal: boolean
  external: boolean
  moderator: boolean
  internalExecution: boolean
  externalExecution: boolean
}

export type Initiator = {
  account: { accountId: string; accountName: string }
  user?: User
  invitationSentOn?: string
  canUploadSignaturePagesFor?: string[]
}

export type User = {
  id: string
  firstName: string
  lastName: string
  email: string
  profileColour?: ProfileColour
}

export type Receiver = {
  account?: { accountId: string; accountName: string }
  user: { email: string; emailFailedToDeliver?: boolean; additionalEmails?: string[] }
  invitationAcceptedOn?: string
}

export type Requester = {
  position: PartyPosition
}

export type Approval = {
  state: ApprovalStatus
  approvalRules: ApproversWithQuorum
  approval: ApprovalDecisions
  approvalInProgress?: ApprovalDecisions
}

export type ApproversWithQuorum = {
  namedApprovers: ApproverInRules[]
  quorum: number
  isBroken: boolean
}

export type ApproverInRules = {
  userId: string
  email: string
  firstName: string
  lastName: string
  role: string
  brokenBecause?: string
  isAdvisor: boolean
  profileColour?: ProfileColour
}

export type ApprovalDecisions = {
  decision?: string
  namedApprovers: Approver[]
  sentForApprovalOn?: string
  decisionReached?: string
  quorum: number
  overrideApprover?: Approver
  submittedValues: Record<string, unknown>
}

type AnonymousApprover = {
  decisionReached?: string
  decision?: ApprovalStatus
}

export type NamedApprover = AnonymousApprover & {
  userId: string
  email: string
  firstName: string
  lastName: string
  role: string
  isAdvisor: boolean
  eventId?: string
  comment?: string
  profileColour?: ProfileColour
}

export type Approver = NamedApprover | AnonymousApprover

export type JobError = {
  stateWhenFailureOccurred: string
  error: { code?: string; causes?: { causeType?: string; description?: string }[]; message?: string }
}

export type EstablishedParty = {
  type: 'established_party'
  partyId: string
  position: PartyPosition
  account: { accountId: string; accountName: string }
  entity: Entity
  activeUser?: User
  isTurn: boolean
}

export type StubbedParty = {
  type: 'stubbed_party'
  position: PartyPosition
  account?: { accountId: string; accountName: string }
  entity?: Entity
}

export type Party = EstablishedParty | StubbedParty

export const isEstablishedParty = (maybe: Party): maybe is EstablishedParty => maybe.type === 'established_party'

export type ChaserEmailInfo = {
  lastNotification: string
  emailFailedToDeliver: boolean
}

export type Entity = {
  id: string
  name: string
  lei?: string
  alternativeAsciiName?: string
  legalJurisdiction?: string
  externalAttachments?: string[]
}

export type Counterparty = {
  entity: Entity
  email?: string
  error?: string
  referenceIdError?: string
  referenceId?: string
  displayReferenceId?: string
  externalComment?: string
  externalAttachments?: string[]
  bulkDraft?: { overrideAnswers: PartyAnswers }
  additionalEmails?: EmailWithError[]
}

export type ActiveUser = {
  firstName: string
  lastName: string
  isCurrentUser: boolean
  isAdvisor: boolean
  profileColour?: ProfileColour
}

export type NestedAnswersSummary = {
  completedElections: string[]
  completedElectionsMySide: string[]
  completedElectionsOtherSides?: string[]
  matchingElections?: number
  matchingElectionIds?: string[]
  answeredElections: string[]
  answeredElectionsMySide: string[]
  answeredElectionsOtherSides?: string[]
  pendingRemovalMySide?: string[]
  pendingRemovalOtherSide?: string[]
}

export type LegacyNestedAnswersSummary = NestedAnswersSummary & {
  completedElectionsCount: number
  completedElectionIds: string[]
}

export type NegotiationCustomFields = { referenceId?: string; displayReferenceId?: string }

export type ValidEntity = {
  type: 'ValidEntityDto'
  entity: Entity
}
export type InvalidEntity = {
  type: 'InvalidEntityDto'
  entityId: EntityId
}
export type ValidatedEntity = ValidEntity | InvalidEntity
export type ValidatedEntities = {
  validatedEntities: ValidatedEntity[]
}

export type EmailWithError = {
  email: string
  error?: string
}
export type DraftReceiverEmailValidation = {
  primaryEmail: EmailWithError
  additionalEmails?: EmailWithError[]
}

type StaleNegotiationBucket = {
  ourCount: number
  counterpartyCount: number
  finalDocumentApprovals: number
  electionLevelApprovals: number
  fromMillis: number
  toMillis: number
}

type SidedNegotiationCounts = {
  invitations: number
  negotiations: number
  executions: number
  finalDocumentApprovals: number
  electionLevelApprovals: number
  approvals: number
}

export type SubAccountComplexStatistics = {
  negotiations: {
    staleNegotiations: StaleNegotiationBucket[]
    ourTurn: SidedNegotiationCounts
    counterpartyTurn: SidedNegotiationCounts
    users: {
      userId: string
      firstName: string
      lastName: string
      isAdvisor: boolean
      counts: SidedNegotiationCounts
    }[]
    executedNegotiationCount: number
  }
  entityCount: number
  userCount: number
  presetCount: number
}

export type NegotiationVersionDetails = {
  version: string
  creationTime?: string
  comparePosition?: boolean
  documentVersion?: string
  unreservedElections: string[]
}

type DocumentSchemaChanges = {
  negotiationVersion: string
  schemaVersion: string
  addedElections: string[]
  removedElections: string[]
  updatedElections: string[]
}

type UnreservedElections = {
  negotiationVersion: string
  unreservedElections: string[]
}

export type VersionedPartyAnswers = {
  answers: PartyAnswers
  schema: DocumentSchema
  schemaChanges: DocumentSchemaChanges[]
  unreservedElections: UnreservedElections[]
}

export type NegotiationActiveUser = {
  userFullName: string
  accountName?: { accountName: string }
  userId: string
  roleLabel: string
  profileColour?: ProfileColour
  isAdvisor?: boolean
}

export type AnswerSummary = {
  agreed: boolean
  completed: boolean
  matched: boolean
  percentComplete: number
  percentMatched: number
  totalVisible: string[]
  totalCompleted: string[]
  totalMatched: string[]
  amend: boolean
  amendMatched?: boolean
  cpHasAnswered: boolean
}

export enum ElectionOverviewStatus {
  Agreed = 'agreed',
  NotAgreed = 'notAgreed',
  Complete = 'complete',
  Incomplete = 'incomplete',
}

export interface ElectionProgressStatus {
  status: ElectionOverviewStatus
  left: Required<LinearProgressProps>['color']
  right: Required<LinearProgressProps>['color']
  progress: number
}

export type ElectionLabels = { [electionId: string]: string }

export type ApprovalState = 'Pending' | 'ApprovalRequired' | 'Rejected' | 'Approved' | 'NoApprovalRequired'

export type ForkVersionValue = `Original ${string}` | `A&R ${string}` | ''
