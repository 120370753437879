import Box from '@mui/material/Box'
import { styled, alpha } from '@mui/material/styles'

import { negotiationSidebarWidth } from 'components/organisms/NavigationSidebar/NavigationSidebar.components'
import { negotiationBodyContainerMargin } from 'components/templates/Negotiation/Negotiation.utils'

const leftAndRightBodyMargin = negotiationBodyContainerMargin * 2
export const confirmModalContainerWidth = `calc(100vw - ${negotiationSidebarWidth + leftAndRightBodyMargin}px)`

export const ConfirmModalContainer = styled(Box)(({ theme }) => ({
  borderRadius: '20px',
  padding: '10px',
  width: confirmModalContainerWidth,
  backgroundColor: theme.palette.lightSurface.main,
  boxShadow: `0 1px 4px 0 ${alpha(theme.palette.common.black, 0.25)}`,

  '&:before': {
    content: '" "',
    width: '36px',
    height: '36px',
    backgroundColor: theme.palette.lightSurface.main,
    position: 'absolute',
    top: '-12px',
    right: '40px',
    transform: 'rotate(45deg)',
    boxShadow: `0 1px 4px 0 ${alpha(theme.palette.common.black, 0.25)}`,
    zIndex: -1,
  },
  '&:after': {
    content: '" "',
    width: '36px',
    height: '36px',
    backgroundColor: theme.palette.lightSurface.main,
    position: 'absolute',
    top: '-12px',
    right: '40px',
    transform: 'rotate(45deg)',
    zIndex: 1,
  },
}))

export default {}
