import { MixpanelAPI } from './Mixpanel.types'

// Default mixpanel stub, for situations when Mixpanel isn't enabled, does nothing
export default class NoopMixpanelInstance implements MixpanelAPI {
  identify() {}

  track() {}

  trackView() {}

  set() {}

  reset() {}

  alias() {}

  trackEvent = {
    LOGIN() {},
    QUICK_START_NEGOTIATION() {},
    NEGOTIATION_CREATED() {},
    ADD_COMMENT() {},
    EXTERNAL_COMMENT_COVER_NOTE() {},
    DOCLEVEL_APPROVAL_APPROVED() {},
    DOCLEVEL_APPROVAL_REJECTED() {},
    ELECTION_APPROVAL_APPROVED() {},
    ELECTION_APPROVAL_REJECTED() {},
    NEGOTIATION_EXECUTED() {},
  }
}
