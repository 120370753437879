import { PositionsDict } from 'components/molecules/Modal/ModalWrapper.types'

export const predefinedPositions: PositionsDict = {
  top: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
  },
  right: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
  bottom: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
  left: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
  center: {
    anchorOrigin: {
      vertical: 'center',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'center',
      horizontal: 'center',
    },
  },
}

export default {}
